import React from "react"
import {graphql } from "gatsby"
import get from "lodash/get"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  FormattedMessage,
  injectIntl,
  useIntl,
  Link,
} from "gatsby-plugin-react-intl"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import * as styles from "./blog-post.scss"
import { PostsCards } from "../../components/lib/posts-cards/posts-cards"
const readingTime = require("reading-time/lib/reading-time")

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, "data.contentfulBlog")
    const previous = get(this.props, "data.previous")
    const next = get(this.props, "data.next")

    const latestPosts = get(this.props, "data.latestPosts")

    const plainTextBody = documentToPlainTextString(JSON.parse(post.body.raw))
    const readingTimeStats = readingTime(plainTextBody)

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: node => {
          const assetId = node.data.target.sys.id
          const asset = post.body.references.find(
            reference => reference.contentful_id === assetId
          )

          if (!asset || asset.__typename !== "ContentfulAsset") {
            return null
          }

          const { gatsbyImageData, description } = asset
          return (
            <div className="blog-post-image">
              <GatsbyImage
                image={getImage(gatsbyImageData)}
                alt={description || ""}
              />
            </div>
          )
        },
      },
    }

    const getPostType = () => {
      switch (post.type) {
        case "case":
          return "CASE STUDY"
        case "blog":
          return "BLOG POST"
        default:
          break
      }
    }

    return (
      <Layout location={this.props.location}>
        <Seo
          title={post.title}
          image={`https:${post.image.resize.src}`}
          description={post.description}
        />
        <div className="page post-page">
          <div className="post-title">
            <div className="post-title-wrap">
              <div className="left">
                <p className="post-type">{getPostType()}</p>
                <h1>{post.title}</h1>
                <p>{post.longDescription.longDescription}</p>
                <p className="publish-date">{post.publishDate}</p>
                <p className="publish-date">{readingTimeStats.text}</p>
              </div>

              {/* <div className="right">
                <img src={`https:${post.image.resize.src}`} />
              </div> */}
            </div>
          </div>

          <div className="post-body">
            {post.body?.raw &&
              renderRichText(post.body, options, {
                references: post.body.references,
              })}
          </div>
        </div>

        <section className="more-posts container section">
          <h1>
            {" "}
            <FormattedMessage id="general.readMore" />
          </h1>
          <div className="blog-post-cards">
            <PostsCards posts={latestPosts.nodes}></PostsCards>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousBlogSlug: String
    $nextBlogSlug: String
    $locale: String
  ) {
    contentfulBlog(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      slug
      title
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      image {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      type
      description
      longDescription {
        longDescription
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            description
          }
        }
      }
    }
    previous: contentfulBlog(slug: { eq: $previousBlogSlug }) {
      slug
      title
    }
    next: contentfulBlog(slug: { eq: $nextBlogSlug }) {
      slug
      title
    }

    latestPosts: allContentfulBlog(
      limit: 3
      filter: { node_locale: { eq: $locale } }
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        slug
        title
        description
        publishDate(formatString: "MMMM Do, YYYY")
        image {
          file {
            url
          }
        }
      }
    }
  }
`
